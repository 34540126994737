.large-menu-classname ul.slimmenu{
    margin:0px;
}
.collapse-button{
    width:24px; 
}
.collapse-button div[role=button]{
    width:100%; 
    height:24px;
    float: left;
    padding-top: 5px;
}
.collapse-button div[role=button] span{
    width:100%;
    height:2px;
    float: left;
    background: #fff;
    margin-bottom:5px;
}
.collapse-button div[role=button] span:last-child{
    margin:0px;
}
.header-right-panel > div:first-child{
    display: inline-block;
    margin-right: -20px;
}
.header-right-panel .slimmenu li{
    display: inline-block; 
    color: #fff; 
    padding: 5px 20px 5px 15px; 
    border-left: rgba(255,255,255,0.4) solid 1px;
}
.header-right-panel .slimmenu li,
.header-right-panel .slimmenu li a{
    color:#fff;
}

header .collapse-button div[role=button] + div{
    position: absolute;
    width:100%;
    background: #fff;
    left:0px;
    top: 48px;
    box-shadow: #bfbfbf 0px 0px 10px;
}
header .collapse-button div[role=button] + div .slimmenu{
    margin-bottom:0px;
}
header .collapse-button div[role=button] + div .slimmenu li{
    width:100%;
    display: inline-block;
    border-bottom:#e2e2e2 solid 1px;
}
header .collapse-button div[role=button] + div .slimmenu li,
header .collapse-button div[role=button] + div .slimmenu li a{
    color:#4e4e4e;
}
header .collapse-button div[role=button] + div .slimmenu li a{
    display: block;
    padding: 5px 0px;
}
header .collapse-button div[role=button] + div .slimmenu li.active,
header .collapse-button div[role=button] + div .slimmenu li.active a{
    color:#459ced;
}


@media only screen and (max-width:991px){

    .header-right-panel > div:first-child {
        display:inherit;
        float: left;
        margin-right:10px;
    }

}

/*
ul.slimmenu li.active a,
.collapse-button ul.slimmenu li.active a {
color: #daa214;
}
.collapse-button ul.slimmenu {
position: fixed;
width: 100%;
margin: 0px 0 0;
left: 0;
background: #fff;
top: 45px;
list-style-type: none;
margin: 0;
padding:15px 15px 0px 15px;
}

.collapse-button ul.slimmenu li {
width:100%;
position: relative;
display: inline-block;
margin: 0 1px;
background-color: transparent;
border-bottom: solid 1px #dde6ef;
text-align: left;
}

.collapse-button ul.slimmenu>li {
margin-right: 0;
border-left: 0 solid #999;
padding: 0 0px
}

.collapse-button ul.slimmenu li a {
color: #677782; font-size: 15px; text-transform: capitalize; font-weight: 500; font-family: 'Nunito', sans-serif; padding: 0 11px !important; line-height: 60px !important;
}

.collapse-button ul.slimmenu li a.active,
.collapse-button ul.slimmenu li a:hover { color:#f8b91f;}*/